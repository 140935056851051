import styled from "styled-components";
import CurrencyInput from "react-currency-input-field";

const StyledInput = styled(CurrencyInput)`
  background-color: #f3f4f6;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  border: none;
  border-radius: 5px;
  font: inherit;
  leter-spacing: inherit;
  font-size: 1rem;
  font-weight: 400;
  padding-inline: 10px;
  padding-block: 13px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`;

type Props = {
  value: number;
  onChange: (value: number) => void;
};

export default function CurrencyInputCustom(props: Props) {
  return (
    <StyledInput
      id="amount"
      name="amount"
      inputMode={"decimal"}
      decimalsLimit={2}
      onValueChange={(value, name, values) =>
        props.onChange(values?.float || 0)
      }
      suffix=" €"
      allowDecimals={true}
      defaultValue={props.value}
    />
  );
}
