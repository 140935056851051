import axios, { AxiosResponse } from "axios";
import { config } from "../config/config";

axios.defaults.baseURL = config.apiUrl;

const getBaseHeaders = () => ({
  authorization: document.cookie,
  // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

export const getAuth = async <T>(
  url: string,
  params?: any,
  headers?: object
) => {
  const response = await axios.get<any, AxiosResponse<T>>(url, {
    params,
    headers: {
      ...getBaseHeaders(),
      ...(headers || {}),
    },
  });
  return response.data;
};

export const postAuth = async <T>(
  url: string,
  params?: any,
  headers?: object
) => {
  const response = await axios.post<any, AxiosResponse<T>>(url, params, {
    headers: {
      ...getBaseHeaders(),
      ...(headers || {}),
    },
  });
  return response.data;
};

export const patchAuth = async <T>(
  url: string,
  params?: any,
  headers?: object
) => {
  const response = await axios.patch<any, AxiosResponse<T>>(url, params, {
    headers: {
      ...getBaseHeaders(),
      ...(headers || {}),
    },
  });
  return response.data;
};

export const httpDeleteAuth = async <T>(url: string, params?: any) => {
  const response = await axios.delete<any, AxiosResponse<T>>(url, {
    params,
    headers: getBaseHeaders(),
  });
  return response.data;
};
