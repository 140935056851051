import { ButtonGroup, Card, CardActions, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import RestoreIcon from "@mui/icons-material/Restore";
import EditIcon from "@mui/icons-material/Edit";
import { Expense } from "../../types/Expense";
import { formatDate } from "../../utils/date";
import ExpenseDialog from "./ExpenseDialog";
import { ExpenseType } from "../../enums/Expense";
import DeleteExpenseDialog from "./DeleteExpenseDialog";
import React from "react";

interface CardExpenseProps {
  expense: Expense;
}

export default function CardExpense({ expense }: CardExpenseProps) {
  return (
    <Card sx={{ marginBottom: 2 }}>
      <CardHeader
        title={expense.title}
        action={
          <ButtonGroup disableElevation variant={"outlined"} aria-label="Basic button group" orientation={"horizontal"} size={"small"}>
            <DeleteExpenseDialog expense={expense}  />
            <ExpenseDialog type={expense.type as ExpenseType} action={'update'} expense={expense} icon={EditIcon} />
          </ButtonGroup>
      }
      >
        <CardActions>

        </CardActions>
      </CardHeader>
      <CardContent>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
          }}
        >
          $ {expense.amount}
        </Typography>
        <Stack direction={"row"} className="CardBottom">
          <CalendarTodayIcon fontSize="small" />
          <Typography>{formatDate(expense.date)}</Typography>
          {expense.recurrent ? (
            <RestoreIcon fontSize="small" sx={{ marginLeft: "auto" }} />
          ) : (
            ""
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
