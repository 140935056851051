import { Card, CardContent, CardHeader } from "@mui/material";
import CardExpense from "./CardExpense";
import ExpenseDialog from "./ExpenseDialog";
import { ExpenseType } from "../../enums/Expense";
import { Expense } from "../../types/Expense";
import { formatCurrency } from "../../utils/currency";
import AddIcon from "@mui/icons-material/Add";

interface CategoryExpenseProps {
  title: string;
  amount: number;
  cardType: ExpenseType;
  data: any;
}

export default function CategoryExpense({
  title,
  amount,
  cardType,
  data,
}: CategoryExpenseProps) {
  return (
    <Card
      style={{
        maxHeight: "80vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardHeader
        className={cardType}
        title={title}
        subheader={`${formatCurrency(amount)} €`}
        subheaderTypographyProps={{fontSize: "1.2rem", fontWeight: "bold", color: "black"}}
        action={
            <ExpenseDialog
              type={cardType}
              action={"add"}
              icon={AddIcon}
            />
        }
        titleTypographyProps={{
          fontWeight: "bold",
        }}
      ></CardHeader>
      <CardContent
        style={{
          overflowY: "auto",
        }}
      >
        {data.map((expense: Expense) => {
          return <CardExpense key={expense.id} expense={expense}></CardExpense>;
        })}
      </CardContent>
    </Card>
  );
}
