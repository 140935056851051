import { getAuth, httpDeleteAuth, patchAuth, postAuth } from "../client/axiosClient";
import { CreateExpense, Expense, ExpenseGrouped, UpdateExpense } from "../types/Expense";

export const getExpensesGrouped = (date: string) => {
  return getAuth<ExpenseGrouped>(`expense/grouped/${date}`);
};

export const postExpense = (expense: CreateExpense) => {
  return postAuth<Expense>("expense", expense);
};

export const deleteExpense = (id: string) => {
  return httpDeleteAuth<Expense>(`expense/${id}`);
};

export const updateExpense = (id: string, expense: UpdateExpense) => {
  return patchAuth(`expense/${id}`, expense);
}

export const getSummary = (date: string) => {
  return getAuth(`expense/summary/${date}`);
};
