import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Expense } from "../../types/Expense";
import { deleteExpense } from "../../services/expense";

type Props = {
  expense: Expense;
};

export default function DeleteExpenseDialog(props: Props) {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const mutation = useMutation({
    mutationFn: (expenseId: string) => {
      return deleteExpense(expenseId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["expensesGrouped"] });
      queryClient.invalidateQueries({ queryKey: ["summary"] });
      setOpen(false);
    },
  });

  return (
    <React.Fragment>
      <Button aria-label="settings" onClick={handleClickOpen}>
        <DeleteOutlineIcon />
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Eliminar gasto</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estás seguro que deseas eliminar este gasto?
            <br />
            {props.expense.title}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={() => mutation.mutate(props.expense.id)} autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
